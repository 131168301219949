import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsPage from '../views/TabsPage.vue'
import { useSesionStore } from '@/stores/sesionStore';
import { SesionService } from '@/services/SesionService';
import { mainStore } from '@/stores/mainStore';
import BaseLayout from '@/layouts/BaseLayout.vue'
import userAuth from '@/composables/userAuth';

const routes: Array<RouteRecordRaw> = [
  { 
    path: '/',
    redirect: 'sesiones',
    meta:{requiredAuth:true}
  },
  {
    path: '/test',
    name: 'Test',
    component: () => import('@/views/TestPage.vue'),
    meta:{requiredAuth:false}
  },
  {
    path: '/whatsappLauncher',
    name: 'Whatsapp Launcher',
    component: () => import('@/views/WhatsappLauncher.vue'),
    meta:{requiredAuth:true, permission:"Buscar alumnos para Whatsapp Launcher"}
  },
  {
    path: '/uploadVideo/:sesion_id',
    name: 'uploadVideo',
    component: () => import('@/components/Sesion/uploadVideo.vue'),
    meta:{requiredAuth:true}
  },
  {
    path: '/payment/:asistencia_id',
    name: 'Pago',
    component: () => import('@/views/PaymentPage.vue'),
    meta:{requiredAuth:true}
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/views/LoginPage.vue')
  },
  {
    path: '/',
    component: BaseLayout,
    children: [
      {
        path: 'sesiones',
        name: 'Sesiones',
        component: () => import('@/components/Sesion/SesionesList.vue'),
        meta:{requiredAuth:true, permission: "Ver listado de sesiones"}
      },
      {
        path: '/noAuth',
        name: 'NoAuth',
        component: () => import('@/views/NoAuth.vue'),
        meta:{requiredAuth:false}
      },
      {
        path: 'alquileres',
        name: 'Alquiler de instalaciones',
        component: () => import('@/components/Sesion/AlquilerCrud.vue'),
        meta:{requiredAuth:true}
      },
      {
        path: 'necesidades',
        name: 'Detección de necesidades',
        component: () => import('@/components/Galidancia/NecesidadesForm.vue'),
        meta:{requiredAuth:true}
      },
      {
        path: 'eventos',
        name: 'Eventos',
        component: () => import('@/components/Sesion/EventosList.vue'),
        meta:{requiredAuth:true}
      },
    ]
  },

  {
    path: '/sesionLog/:sesion_id',
    name: 'sesionLog',
    component: () => import('@/components/Sesion/LogComponent.vue')
  },
  {
    path: '/asientos/:user_id',
    name: 'Pagos',
    component: () => import('@/components/Asiento/AsientoList.vue'),
    meta:{requiredAuth:true, permission: "asiento list"}
  },
  {
    path: '/ayudantes/:sesion_id',
    name: 'Ayudantes',
    component: () => import('@/components/Sesion/AyudantesList.vue'),
    meta:{requiredAuth:true, permission: "user list"}
  },
  {
    path: '/stat/:sesion_id',
    name: 'Estadisticas de la clase',
    component: () => import('@/views/Clase/StatPage.vue')
  },

  {
    path: '/sesion/noSeAnotan/:sesion_id',
    name: 'No se anotan',
    component: () => import('@/views/Clase/NoSeAnotan.vue'),
    meta:{requiredAuth:true, permission: "user list"}

  },

  {
    path: '/sesion/search/:sesion_id',
    name: 'sesionSearch',
    component: () => import('@/components/Sesion/AlumnosSesionesSearch.vue'),
    meta:{requiredAuth:true, permission: "user list"}
  },

  {
    path: '/SesionesRelacionadas/:sesion_id',
    name: 'SesionesRelacionadas',
    component: () => import('@/components/Sesion/SesionesRelacionadas.vue'),
    meta:{requiredAuth:true, permission: "Ver listado de sesiones"}
  },
  {
    path: '/Organizacion/',
    component: TabsPage,
    children: [
      {
        path: 'Mensajes',
        name: 'Mis Mensajes',
        component: () => import('@/components/Mensaje/MensajeList.vue'),
        meta:{requiredAuth:true}
      },
    ]
  },
  {
    path: '/Tools/',
    component: BaseLayout,
    children: [
      {
        path: 'sesion/:sesion_id',
        name: 'Sesion de clase',
        component: () => import('@/views/Clase/SesionPage.vue'),
        meta:{requiredAuth:true, permission: "user list"}
      },
    ]
  },
  {
    path: '/galidancia',
    name: 'Galidancia',
    component: () => import('@/views/GalidanciaPage.vue'),
  },

  {
    path: '/tabs/',
    component: TabsPage,
    children: [
      {
        path: 'mensajes',
        name: 'Mis mensajes',
        component: () => import('@/components/Mensaje/MensajeList.vue'),
        meta:{requiredAuth:true}
      },
      {
        path: 'home',
        component: () => import('@/views/HomePage.vue'),
        name: 'Galidancia',
        meta:{requiredAuth:true}
      },
      {
        path: 'galidancia',
        component: () => import('@/views/GalidanciaPage.vue'),
        name: 'Galidancia',
        meta:{requiredAuth:true}
      },
    ]
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach( async(to, from, next) => {
  
  if (typeof to.meta.permission === 'string') {
    if(!userAuth().can(to.meta.permission)){
      next({ path: '/noAuth' });
      return;
    }
  }

  console.log("Navegamos hacia ", to.name)
  switch(to.name){
    case 'Sesion de clase': //si vamos hacia una sesion, actualizamos sus datos
      mainStore().setLoading(true, "Cargando sesión...")
      useSesionStore().mySesion = await SesionService.get(Number(to.params.sesion_id)) //traemos sus datos y los guardamos en el store como sesion activa
      //refrescamos el array de mySesiones
      useSesionStore().updateMySesiones()

      mainStore().updateComponentKey(); //actualizamos la clave del componente para que se vuelva a renderizar
      mainStore().setLoading(false)
      break;

    case 'sesionLog':
      mainStore().setLoading(true, "Cargando historico...")
      useSesionStore().mySesionLog = await SesionService.getTracking(Number(to.params.sesion_id))
      mainStore().setLoading(false)
      break;
  }

  next(true)
});

export default router
