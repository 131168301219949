import axios from 'axios';
import tools from "@/composables/tools";
import { Sesion } from '@/interfaces/Sesion'
import { Asistencia } from '@/interfaces/Asistencia'
import { Video } from '@/interfaces/Video'
import { SesionLog } from '@/interfaces/SesionLog'
import { useSesionStore } from '@/stores/sesionStore';
import { ContajeSesion } from '@/interfaces/ContajeSesion'

export abstract class SesionService{
    static async get(sesion_id:number) : Promise<Sesion> {
        try {
            const response = await axios.get('galidancia/sesion/' + sesion_id)
            //asignamos al store lo que traemos por api
            console.log('Asignamos al store la sesion que hemos traido por api', response.data)
            response.data.lastSync = new Date()
            useSesionStore().update(sesion_id, response.data);
            return response.data
        } catch (error) {
            tools().toast('No se pudo cargar la información de la sesión','danger',5000)
            return {} as Sesion
        }
    } 

    static async getAlquileres() : Promise<Sesion[]> {
        try {
            const response = await axios.get('alquileres')
            return response.data
        } catch (error) {
            tools().toast('No se pudo cargar los alquileres','danger',5000)
            return [] as Sesion[]
        }
    }

    static async getSesiones(from:string,to:string) : Promise<Sesion[]> {
        try {
            console.log('Solititando sesiones')
            const response = await axios.get('galidancia/sesions/' + from + '/' + to)
            response.data.map((sesion: Sesion) => {
                sesion.id = Number(sesion.id); //forzamos a que sea un número
                return sesion;
            });
            console.log('Sesiones recibidas')
            return response.data
        } catch (error) {
            tools().toast('No se pueden cargar las sesiones','danger',5000)
            return [] as Sesion[]
        }
    } 

    static async getEventos() : Promise<Sesion[]> {
        try {
            console.log('Solititando eventos')
            const response = await axios.get('galidancia/eventos')
            return response.data
        } catch (error) {
            tools().toast('No se pueden cargar los eventos','danger',5000)
            return [] as Sesion[]
        }
    } 

    static async related(sesion_id:number) : Promise<Sesion[]> {
        try {
            const response = await axios.get('sesiones/related/' + sesion_id)
            return response.data
        } catch (error) {
            tools().toast('No se pueden cargar las sesiones relacionadas','danger',5000)
            return [] as Sesion[]
        }
    } 

    /**
     * establece los ayudantes maximos que puede tener una sesion
     * 
    */
    static async setMaxAyudantes(sesion_id:number,max_hombres:number,max_mujeres) {
        try {
            const response = await axios.patch('sesion/setAyudantesMaximos', {sesion_id: sesion_id, maxChicos: max_hombres, maxChicas: max_mujeres})
            tools().toast('Se han guardado los ayudantes máximos correctamente','success',2100)

            //guardamos en el store los datos actualizados
            useSesionStore().updateMaxAyudantes(sesion_id,Number(response.data.maxChicos),Number(response.data.maxChicas))  
        } catch (error) {
            tools().toast('No se pueden cargar las sesiones relacionadas','danger',5000)
        }     
    }

    static async cobrar(cart:object[],user_id:number,amount:number,sesion_id:number) {
        try {
            const response = await axios.post('galidancia/cobrarActividad', {cart: cart, user_id: user_id, amount: amount, sesion_id: sesion_id})
            tools().toast('Se ha cobrado correctamente','success',2100)
        } catch (error) {
            tools().toast('No se ha podido cobrar','danger',5000)
        }     
    }

    
    static async videos(sesion_id:number) : Promise<Video[]>{
        try {
            const response = await axios.get('sesion/videos/' + sesion_id)
            return response.data
        } catch (error) {
            tools().toast('No se pudo cargar los videos de la sesión #' + sesion_id,'danger',5000)
            tools().setLog('No se pudo cargar los videos de la sesión #' + sesion_id,'error')
            return [] as Video[]
        }
    }

   
    //confirma si un alumno asistio o no a clase
    static async confirmarAsistencia(myAsistencia:Asistencia,status:0|1|2) : Promise<Asistencia>{
        try {
            const tmp = {
                 asistio: status,
            } as Asistencia
            const response = await axios.put('galidancia/asistencias/' + myAsistencia.id,tmp)
            tools().toast('Asistencia confirmada','success',2100)
            return response.data
        } catch (error) {
            tools().toast('No se pudo confirmar la asistencia','danger',5000)
            tools().setLog('No se pudo confirmar la asistencia','error')
            return {} as Asistencia
        }
    }

    static async toggleAyudante(asistencia_id:number) : Promise<Asistencia>{
        try {
            const response = await axios.patch('asistencia/toggleAyudante/' + asistencia_id)
            tools().toast('Se puso como ayudante correctamente','success',2100)
            return response.data
        } catch (error) {
            tools().toast('No se pudo poner como ayudante','danger',5000)
            return {} as Asistencia
        }
    }

    static async toggleRecuperacion(asistencia_id:number) : Promise<Asistencia>{
        try {
            const response = await axios.patch('asistencia/toggleRecuperacion/' + asistencia_id)
            tools().toast('Se puso como recuperación correctamente','success',2100)
            return response.data
        } catch (error) {
            tools().toast('No se pudo poner como recuperación','danger',5000)
            return {} as Asistencia
        }
    }
    
    static async togglePrueba(asistencia_id:number) : Promise<Asistencia>{
        try {
            const response = await axios.patch('asistencia/togglePrueba/' + asistencia_id)
            tools().toast('Se puso como prueba correctamente','success',2100)
            return response.data
        } catch (error) {
            tools().toast('No se pudo poner como prueba','danger',5000)
            return {} as Asistencia
        }
    }

    static async toggleSexo(asistencia_id:number) : Promise<Asistencia>{
        try {
            const response = await axios.patch('asistencia/toggleSexo/' + asistencia_id)
            tools().toast('Se cambio el sexo correctamente','success',2100)
            return response.data
        } catch (error) {
            tools().toast('No se pudo cambiar el sexo','danger',5000)
            return {} as Asistencia
        }
    }

    static async getTracking(sesion_id:number) : Promise<SesionLog[]>{
        try {
            const response = await axios.get('sesion/tracking/' + sesion_id)
            return response.data
        } catch (error) {
            tools().toast('No se pudo cargar el log de la sesión #' + sesion_id,'danger',5000)
            return {} as SesionLog[]
        }
    }

    static async setWhatsappSent(sesion_id:number,codigo:string,tlf:string,text:string,user_id:number){
            const response = await axios.patch('sesion/setWhatsappSent/' + sesion_id, {codigo: codigo, tlf: tlf, text: text, user_id: user_id})
    }


    static async updateProfesor(sesion_id:number,profesor_id:number) : Promise<boolean>{
        try {
            const response = await axios.patch('/sesiones/' + sesion_id + '/updateProfesor/' + profesor_id)
            useSesionStore().updateProfesor(sesion_id, profesor_id)
            tools().toast('Se actualizó el profesor correctamente','success',2100)
            return true
        } catch (error) {
            console.log('No se pudo cambiar el profesor')
            console.log(error)
            tools().toast('No se pudo cambiar el profesor','danger',5000)
            return false
        }
    }


    static async updateCoordinador(sesion_id:number,coordinador_id:number) : Promise<boolean>{
        try {
            const response = await axios.patch('/sesiones/' + sesion_id + '/updateCoordinador/' + coordinador_id)
            tools().toast('Se actualizó el coordinador correctamente','success',2100)
            return true
        } catch (error) {
            tools().toast('No se pudo cambiar el coordinador','danger',5000)
            return false
        }
    }

    static async getAsistentes(sesion_id:number) : Promise<Asistencia[]>{ 
        try {
            const response = await axios.get('sesion/asistentes/' + sesion_id)
            return response.data
        } catch (error) {
            tools().toast('No se pudo cargar los asistentes de la sesión','danger',5000)
            return [] as Asistencia[]
        }
    }

    static async getNoSeAnotan(sesion_id:number) : Promise<[]>{ 
        try {
            const response = await axios.get('sesion/noSeAnotan/' + sesion_id)
            return response.data
        } catch (error) {
            console.log(error)
            tools().toast('No se pudo cargar lista de alumnos que no se anotan','danger',5000)
            return []
        }
    }

    static async ayudantesHabituales(sesion_id:number) : Promise<Asistencia[]>{ 
        try {
            const response = await axios.get('sesion/' + sesion_id + '/ayudantesHabituales')
            return response.data
        } catch (error) {
            tools().toast('No se pudo cargar los ayudantes habituales','danger',5000)
            return [] as Asistencia[]
        }
    }

    static async ayudantesNoHabituales(sesion_id:number,page = 0,sexo,nombre) : Promise<Asistencia[]>{ 
        try {
            const response = await axios.get('sesion/' + sesion_id + '/' + page +'/ayudantesNoHabituales',{params: {sexo: sexo,nombre: nombre}})
            return response.data
        } catch (error) {
            tools().toast('No se pudo cargar los ayudantes no habituales','danger',5000)
            return [] as Asistencia[]
        }
    }

    static async create(mySesion:Sesion) : Promise<Sesion|boolean> {
        try {
            const response = await axios.post('galidancia/sesiones',mySesion)
            tools().toast('La sesión se ha registrado correctamente','success',5000)
            return response.data
        } catch (error) {
            return false
        }
    }

    static async update(mySesion:Sesion) : Promise<Sesion|boolean> {
        try {
            const response = await axios.put('galidancia/sesion', mySesion)
            tools().toast('La sesión se ha actualizado correctamente','success',5000)
            return response.data
        } catch (error) {
            return false
        }
    }

    static async delete(sesion_id:number) : Promise<boolean> {
        try {
            const response = await axios.delete('galidancia/sesion/' + sesion_id)
            tools().toast('Sesión eliminada correctamente','success',5000)
            return response.data
        } catch (error) {
            tools().toast('No se pudo eliminar la sesión','danger',5000)
            return false
        }
    }

    static async avisarAlumnosVideoDisponible(sesion_id:number) : Promise<boolean> {
        try {
            const response = await axios.post('sesion/sendWhatsappVideoDisponible/' + sesion_id)
            tools().toast('Se ha avisado a los alumnos que el video está disponible','success',5000)
            return response.data
        } catch (error) {
            tools().toast('No se pudo avisar a los alumnos','danger',5000)
            return false
        }
    }

    static async getContajes(sesion_id:number) : Promise<ContajeSesion[]>{
        try {
            const response = await axios.get('contajeSesion/' + sesion_id )
            return response.data
        } catch (error) {
            tools().toast('No se pudo cargar los contajes de la sesion','danger',5000)
            return [] as ContajeSesion[]
        }
    }
}